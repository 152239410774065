<template>
  <royalty-incentiveSLD
    v-model="kind"
    :title="title"
  />
</template>

<script>
export default {
  components: {
    RoyaltyIncentiveSLD: () => import(/* webpackChunkName: "royaltyIncentivesld" */ '@/components/RoyaltyIncentiveSLD'),
  },
  data () {
    return {
      kind: 'monthly',
      title: 'Monthly',
    }
  },
}
</script>
